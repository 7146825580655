<script>
import { uploadFileToPublic } from '@/api/Common'


export default {
  name: 'UploadImage',
  props: {
    value: { type: Array, default: () => [] },
    maxSize: { type: Number, default: 2 },
    accepts: { type: Array, default: () => ['.png'] },
    limit: { type: Number, default: 1 },

    multiple: { type: Boolean, default: false },
    disabled: { type: Boolean, default: false },

    widthRecommendation: { type: Number, default: 0 },
    heightRecommendation: { type: Number, default: 0 }
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  data() {
    return {
      fileList: [],

      previewShown: false,
      previewUrl: ''
    }
  },
  watch: {
    value: {
      handler() {
        if(!this.value?.length) {
          return
        }

        this.fileList = this.value
      },
      immediate: true
    }
  },
  methods: {

    beforeUpload(file) {
      const types = this.accepts.map(i => i.replace(/^\.*/, ''))
      const type = file.type
      const size = file.size

      return new Promise((resolve, reject) => {
        if(types.every((t) => !type.includes(t))) {
          this.$message.info(`图片仅支持 ${ this.accepts.join('、') } 格式，请重新选择图片`)
          return reject('ImageTypeError')
        }

        if(size > this.maxSize * 1024 * 1024) {
          this.$message.error(`文件大小限制为${ this.maxSize }M以内，请更换图片`)
          return reject('ImageSizeError')
        }
        return resolve()
      })
    },


    async customRequest(evt) {
      const { file, onError, onProgress, onSuccess } = evt || {}

      const formData = new FormData()
      formData.append('file', file)

      this.$emit('loading', true)
      await uploadFileToPublic(formData).then((data) => {
        onProgress({ percent: 100 }, file)
        onSuccess(data, file)
      }).catch((error) => onError(error, file))
      this.$emit('loading', false)
    },

    onUploadChange({ file, fileList }) {
      this.fileList = fileList

      if(file.originFileObj) {
        file.tempUrl = window.URL.createObjectURL(file.originFileObj)
      }

      if(file.status === 'done') {
        file.url = file.response.data?.url || ''
        file.fileResourceId = file.response.data?.file_resource_id || ''

        this.$emit('change', this.fileList)
      }
    },

    onPreview(file) {
      this.previewUrl = file.url || file.response?.data?.url || ''
      this.previewShown = true
    },
    onDelete(file) {
      const index = this.fileList.findIndex(i => i.uid === file.uid)
      this.fileList.splice(index, 1)

      this.$emit('change', this.fileList)
    }

  }
}
</script>

<template>
  <div>
    <a-upload-dragger
      :file-list="fileList"
      :multiple="multiple"
      :accept="accepts.join(',')"
      :disabled="disabled"
      :showUploadList="false"
      class="uploader"
      :custom-request="customRequest"
      :beforeUpload="beforeUpload"
      @change="onUploadChange">
      <div v-if="!fileList.length" class="placeholder">
        <img src="./img/inbox.png" alt="" class="icon">
        <div class="title">点击或拖拽图片到此完成上传</div>
        <div class="message">
          <template>支持上传 {{ accepts.join('、') }} 格式图片，图片大小请勿超过 {{ maxSize }}M</template>
          <template v-if="widthRecommendation || heightRecommendation">，建议尺寸{{ widthRecommendation }}*{{ heightRecommendation }}px</template>
        </div>
      </div>
      <div v-else class="placeholder" @click.stop>
        <div v-for="file in fileList" :key="file.uid" class="img-wrapper" @click.stop>
          <img :src="file.url || file.response?.data?.url || file.tempUrl || ''" alt="" class="img">

          <a-spin v-if="file.status !== 'done'" tip="上传中..." class="loading" />
          <div v-else class="action" @click.stop>
            <a-icon type="eye" class="preview-icon" @click.stop="onPreview(file)" />
            <a-icon type="delete" class="delete-icon" @click.stop="onDelete(file)" />
          </div>
        </div>
      </div>
    </a-upload-dragger>

    <a-modal v-model="previewShown" :footer="null">
      <img :src="previewUrl" alt="" class="img">
    </a-modal>
  </div>
</template>

<style scoped lang="scss">
.uploader {
  display: block;
  width: 395px;
  min-height: 150px;

  ::v-deep .ant-upload {
    padding: 0;
  }
}

.img {
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.placeholder {
  height: 170px;
  padding: 16px 0;
  background: #FAFAFA;

  .img-wrapper {
    position: relative;
    width: 100%;
    height: 100%;

    .img {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  .loading {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.2);
  }

  .action {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: rgba(0, 0, 0, 0.4);

      .preview-icon, .delete-icon {
        display: block;
        font-size: 18px;
      }
    }

    .preview-icon {
      display: none;
      margin-right: 12px;
      font-size: 22px;
      color: #FFFFFF;
    }

    .delete-icon {
      display: none;
      font-size: 18px;
      color: #FFFFFF;
    }
  }

  .icon {
    display: block;
    width: 48px;
    height: 48px;
    margin: 0 auto 20px;
  }

  .title {
    height: 24px;
    margin-bottom: 4px;
    font-size: 16px;
    text-align: center;
    color: rgba(0, 0, 0, 0.85);
  }

  .message {
    min-height: 22px;
    font-size: 14px;
    text-align: center;
    color: rgba(0, 0, 0, 0.45);
  }
}
</style>
