import request from '@/utils/Request';

// 修改积分
export function updConsumptionConf (params) {
  return request({
    url: `/shop/admin/consumptionConf/updConsumptionConf`,
    method: 'post',
    data: params,
  });
}


// 获取积分配置
export function consumptionConfIndex (params) {
  return request({
    url: `/shop/admin/consumptionConf/index`,
    method: 'get',
    params,
  });
}