import request from '@/utils/Request';

// 证书编码配置列表
export function getCertificateCodeList(params) {
  return request({
    url: `/certificate/admin/codeconf`,
    method: 'get',
    params,
  });
}

// 证书编码配置详情
export function getCertificateCodeDetail(id) {
  return request({
    url: `/certificate/admin/codeconf/${id}`,
    method: 'get',
  });
}

// 证书编码配置保存
export function saveCertificateCodeDetail(data) {
  return request({
    url: `/certificate/admin/codeconf/save`,
    method: 'post',
    data,
  });
}

// 证书编码配置--开启公司编码
export function saveCertificateCodeOpenStatus(id, data) {
  return request({
    url: `/certificate/admin/codeconf/companyopen/${id}`,
    method: 'post',
    data,
  });
}

// 证书编码大分类
export function getCertificateCodeCateList(params) {
  return request({
    url: `/public/conf/certificate/bigcate`,
    method: 'get',
    params,
  });
}
