<template>
  <a-modal :title="title"
           :visible="shown"
           :confirm-loading="loading"
           :centered="true"
           :width="600"
           :mask="true"
           :mask-closable="true"
           :destroy-on-close="true"
           :after-close="afterClose"
           @ok="confirm"
           @cancel="close">
    <a-form-model ref="formRef"
                  label-align="right"
                  :model="form"
                  :rules="rules"
                  :label-col="{span: 6}" :wrapper-col="{span: 18}">
      <a-form-model-item label="类型" prop="number_type">
        <SelectCode v-model="form.number_type"
                    :hidden-names="id ? 'company' : 'company,jobs'"
                    :disabled="!!id"
                    @changed="codeChanged"/>
      </a-form-model-item>
      <a-form-model-item label="编码名称" prop="title">
        <a-input v-model="form.title" allow-clear placeholder="请填写编码名称"/>
      </a-form-model-item>
      <a-form-model-item label="编码代号" prop="number_code">
        <a-input v-model="form.number_code" allow-clear placeholder="请填写编码代号"/>
      </a-form-model-item>
      <a-form-model-item label="编码代号格式">
        <div>{{ formatHint }}</div>
      </a-form-model-item>

    </a-form-model>
  </a-modal>
</template>

<script>
import {
  getCertificateCodeDetail,
  saveCertificateCodeDetail,
} from '../api';

import SelectCode from './SelectCode.vue';

export default {
  name: 'EditCodeModal',
  components: {
    SelectCode,
  },
  watch: {
    'form.number_code'(val) {
      this.form.number_code = val?.toUpperCase() || '';
    },
  },
  data() {
    return {
      loading: false,
      shown: false,

      selectedCode: null,
      formatHint: '',
      regText: '',

      title: '',
      id: '',
      form: {
        number_type: '',
        title: '',
        number_code: '',
      },
      rules: {
        number_type: [
          { required: true, trigger: 'change', message: '请选择类型' },
        ],
        title: [
          { required: true, whitespace: true, trigger: 'blur', message: '请填写编码名称' },
        ],
        number_code: [
          { required: true, whitespace: true, trigger: 'blur', message: '请填写编码代号' },
        ],
      },
    };
  },
  methods: {
    show({ title, id } = {}) {
      this.title = title || '';
      this.id = id || 0;

      this.getData();

      this.shown = true;
    },
    close() {
      this.shown = false;
    },
    afterClose() {
      this.title = '';
      this.formatHint = '';
      this.regText = '';
      this.form = {
        number_type: '',
        title: '',
        number_code: '',
      };
    },

    async getData() {
      if (!this.id) {
        return;
      }
      this.loading = true;
      const data = await getCertificateCodeDetail(this.id).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '未查询到编码详情');
        return;
      }
      const { number_type, title = '', number_code = '' } = data?.data || {};
      this.form = { number_type: `${number_type}`, title, number_code };
    },
    async confirm() {
      if (this.loading) {
        return;
      }
      this.loading = true;

      if (!await this.validate()) {
        this.loading = false;
        return;
      }

      const params = { ...this.form };
      if (this.id) {
        params.id = this.id;
      }

      const data = await saveCertificateCodeDetail(params).finally(() => {
        this.loading = false;
      });

      if (!data || data.error_code) {
        this.$message.error(data?.message || '保存失败');
        return;
      }
      this.$message.success(data?.message || '保存成功');
      this.$emit('saved', params);
      this.close();
    },

    validate() {
      return new Promise((resolve) => {
        this.$refs.formRef.validate(valid => {
          if (valid) {
            resolve(true);
          } else {
            resolve(false);
          }
        });
      });
    },

    getCodeReg(en) {
      switch (en) {
        case 'company':
          this.formatHint = '公司名称4位字母';
          return '^[a-zA-Z]{4,4}$';
        case 'domain':
          this.formatHint = '领域2-6位字母';
          return '^[a-zA-Z]{2,6}$';
        case 'jobs':
          this.formatHint = '岗位2位数字';
          return '^[0-9]{2,2}$';
        case 'cate':
          this.formatHint = '类型1位字母';
          return '^[a-zA-Z]{1,1}$';
        default:
          return '';
      }
    },
    codeChanged(evt) {
      this.selectedCode = evt?.length ? evt[0] : null;
      this.regText = this.getCodeReg(this.selectedCode?.en || '');
      const validator = (rule, value, callback) => {
        const reg = new RegExp(this.regText);
        if (!value) {
          callback(new Error('请填写编码代号'));
        } else if (!reg.test(value)) {
          callback(new Error('编码代号格式错误'));
        } else {
          callback();
        }
      };
      this.rules.number_code = { required: true, validator, trigger: 'blur', message: '编码代号格式错误' };
    },
  },
};
</script>

<style scoped lang="scss">

</style>
