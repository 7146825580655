<template>
  <div>
    <div class="right-top-btn-group">
      <a-button class="right-top-btn" @click="add">新建编码</a-button>
    </div>

    <a-table :columns="columns"
             :data-source="data"
             :loading="loading"
             :pagination="false"
             :indent-size="14"
             :bordered="true"
             :expanded-row-keys="expandedRowKeys"
             :expandIcon="expandIcon"
             row-key="rowId"
             class="table small-cell-table"
             @expandedRowsChange="expandedRowsChange">
      <template slot="status" slot-scope="text, record">
        <template v-if="!record.isParent">
          <a-button v-if="record.parentEn === 'company' && !text" type="link"
                    class="btn"
                    @click="open(record)">开启
          </a-button>
          <a-button type="link" class="btn" @click="edit(record)">编辑</a-button>
        </template>
      </template>
      <template slot="footer">
        <Pagination v-if="pagination && pagination.total" :pagination="pagination"
                    class="pagination"
                    @change="onPageChange"
                    @showSizeChange="onSizeChange"/>
      </template>
    </a-table>

    <EditCodeModal ref="editCodeModalRef" @saved="codeSaved"/>
  </div>
</template>

<script>
import store from '@/store';
import Common from '@/utils/Common';
import {
  getCertificateCodeList,
  saveCertificateCodeOpenStatus,
} from './api';

import EditCodeModal from './components/EditCodeModal.vue';
import Pagination, { getPagination } from '@components/Pagination.vue';

const columns = [
  {
    width: '180px',
    align: 'left',
    dataIndex: 'title',
    key: 'title',
    title: '编码类型',
  },
  {
    width: '150px',
    align: 'center',
    dataIndex: 'number_code',
    key: 'number_code',
    title: '编码',
  },
  {
    width: '80px',
    align: 'center',
    dataIndex: 'sort_num_title',
    key: 'sort_num_title',
    title: '编码排序',
  },
  {
    width: '120px',
    align: 'center',
    dataIndex: 'is_open',
    key: 'is_open',
    title: '编码状态',
    scopedSlots: { customRender: 'status' },
  },
  {
    width: '120px',
    align: 'center',
    dataIndex: 'updated_at_1',
    key: 'updated_at_1',
    title: '创建时间',
  },
];

export default {
  name: 'Management',
  components: {
    EditCodeModal,
    Pagination,
  },
  data() {
    return {
      loading: false,
      downloading: false,

      expandedRowKeys: [],
      columns,
      data: [],
      pagination: getPagination(),
    };
  },
  created() {
    this.onSearch();
  },
  beforeRouteEnter(to, from, next) {
    // 证书管理部分账号隐藏菜单
    const noPermissionUserName = [
      'zhengshuwhk',
      'zhengshutd',
      'zhengshuldb',
      'zhengshulyt',
      'zhengshuwza',
      'zhengshucxc',
    ];

    if (!store.state.User.userName || noPermissionUserName.includes(store.state.User.userName)) {
      next('/index/certificate/overview/online');
    } else {
      next();
    }
  },
  methods: {
    onSearch() {
      return this.getData(1, this.pagination.pageSize);
    },
    onReset() {
      this.onSearch();
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize);
    },
    onSizeChange(current, size) {
      this.getData(1, size);
    },
    refreshTable() {
      this.getData(this.pagination.current, this.pagination.pageSize);
    },
    async getData(page, pageSize) {
      if (this.loading) {
        return Promise.reject(new Error('Loading'));
      }
      this.loading = true;

      const params = {
        page,
        per_page: pageSize,
      };

      const data = await getCertificateCodeList(params).finally(() => {
        this.loading = false;
      });

      const list = data?.data || [];
      this.data = list.map((i) => {
        if (i.children?.length) {
          i.children = i.children.map((c) => ({ ...c, rowId: `children-1-${c.id}`, parentEn: i.en }));
        }

        return { ...i, isParent: true, rowId: `parent-${i.id}` };
      });

      const pagination = data?.meta?.pagination || {};
      this.pagination = Common.updatePagination(this.pagination, pagination);
    },

    open(record) {
      this.$confirm({
        content: `确认开启${record.title}？`,
        onOk: () => {
          this.confirmOpen(record);
        },
      });
    },
    async confirmOpen(record) {
      if (this.loading) {
        return;
      }
      this.loading = true;

      const data = saveCertificateCodeOpenStatus(record.id, { is_open: 1 }).finally(() => {
        this.loading = false;
      });
      if (!data || data.error_code) {
        this.$message.error(data?.message || '开启失败');
        return;
      }
      this.$message.success(data?.message || '开启成功');

      this.data.forEach((i) => {
        if (i.en === 'company' && i.children?.length) {
          i.children.forEach((c) => c.is_open = c.id !== record.id ? 0 : 1);
        }
      });
    },

    edit(record) {
      if (this.$refs.editCodeModalRef.show) {
        this.$refs.editCodeModalRef.show({
          id: record.id,
          title: '修改编码',
        });
      }
    },
    add() {
      if (this.$refs.editCodeModalRef.show) {
        this.$refs.editCodeModalRef.show({
          title: '新建编码',
        });
      }
    },

    codeSaved(evt) {
      if (evt?.id) {
        this.refreshTable();
      } else {
        this.onSearch();
      }
    },

    expandIcon(props) {
      if (props.record?.children?.length) {
        if (props.expanded) {
          return <a-button type="link"
                           style="padding: 0 3px; margin: 0;"
                           onClick={e => props.onExpand(props.record, e)}>
            <a-icon type="caret-down" style="color: #333333;"/>
          </a-button>;
        } else {
          return <a-button type="link"
                           style="padding: 0 3px; margin: 0;"
                           onClick={e => props.onExpand(props.record, e)}>
            <a-icon type="caret-right" style="color: #333333;"/>
          </a-button>;
        }
      } else {
        return <span style="margin-right: 14px;"/>;
      }
    },
    expandedRowsChange(expandedRowKeys) {
      this.expandedRowKeys = expandedRowKeys || [];
    },
  },
};
</script>

<style scoped lang="scss">
.btn {
  margin: 0 5px !important;
}
</style>
