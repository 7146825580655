<template>
  <div class="deplete-recording">
    <depleteTitle :title="title" :count="count"></depleteTitle>
    <div class="deplete-recording-query">
      <span> 姓名/手机号/身份证号 : </span>
      <a-input
        placeholder="请输入姓名/手机号/身份证号"
        v-model="params.username_mobile_idcard"
        style="width: 250px"
      />
      <span>
        消耗品 :
        <a-select
          v-if="goodsListOption.length > 0"
          v-model="params.goods_id"
          style="min-width: 100px"
          :options="goodsListOption"
          @change="handleChange"
          :defaultValue="params.goods_id"
        >
        </a-select>
      </span>
      <span> 时间选择 : </span>
      <a-range-picker @change="onChange" v-model="defaultValue" />
    </div>
    <div class="flex flex-end">
      <a-button type="primary" @click="onSearch"> 查询 </a-button>
      <a-button @click="onReset"> 重置 </a-button>
    </div>
    <div class="deplete-table">
      <a-table
        :columns="columns"
        :data-source="data"
        :pagination="false"
        :loading="loading"
        bordered
        key="id"
        row-key="id"
      >
        <span slot="post_name" class="post-name" slot-scope="text, record">
          {{ record.post_name }}
        </span>
        <span slot="course_name" class="post-name" slot-scope="text, record">
          {{ record.course_name }}
        </span>
        <template slot="footer">
          <Pagination
            v-if="pagination && pagination.total"
            :pagination="pagination"
            class="pagination"
            @change="onPageChange"
            @showSizeChange="onSizeChange"
          ></Pagination>
        </template>
      </a-table>
    </div>
  </div>
</template>

<script>
import depleteTitle from './components/title.vue';
import {
  goodsList,
  integralConsumptionIndex
} from '../credit-configuration/index';
import Pagination, { getPagination } from '@components/Pagination';
import moment from 'moment';
const columns = [
  {
    title: '事件ID',
    dataIndex: 'id',
    key: 'id',
    scopedSlots: { customRender: 'id' },
    align: 'center'
  },
  {
    title: '用户名',
    dataIndex: 'username',
    key: 'username',
    align: 'center'
  },
  {
    title: '姓名',
    dataIndex: 'truename',
    key: 'truename',
    ellipsis: true,
    align: 'center'
  },
  {
    title: '手机号',
    dataIndex: 'mobile',
    key: 'mobile',
    ellipsis: true,
    align: 'center'
  },
  {
    title: '岗位',
    dataIndex: 'post_name',
    key: 'post_name',
    ellipsis: true,
    align: 'center',
    scopedSlots: { customRender: 'post_name' }
  },
  {
    title: '触发事件',
    dataIndex: 'goods_name',
    key: 'goods_name',
    ellipsis: true,
    align: 'center'
  },
  {
    title: '消耗积分',
    dataIndex: 'integral',
    key: 'integral',
    ellipsis: true,
    align: 'center'
  },
  {
    title: '关联课程/任务',
    dataIndex: 'course_name',
    key: 'course_name',
    ellipsis: true,
    align: 'center',
    scopedSlots: { customRender: 'course_name' }
  },
  // {
  //   title: '剩余积分',
  //   dataIndex: 'integral_2',
  //   key: 'integral_2',
  //   ellipsis: true,
  //   align: 'center'
  // },
  {
    title: '消耗时间',
    dataIndex: 'created_at',
    key: 'created_at',
    ellipsis: true,
    align: 'center'
  }
];
const data = [];
export default {
  name: 'deplete-recording',
  components: {
    depleteTitle,
    Pagination
  },
  data() {
    return {
      title: '积分消耗',
      count: 0,
      params: {
        username_mobile_idcard: '',
        goods_id: 0,
        start_time: '',
        end_time: '',
        page: 1,
        per_page: 10
      },
      loading: false,
      pagination: getPagination(),
      goodsListOption: [],
      columns,
      data,
      defaultValue: ['', '']
    };
  },
  mounted() {
    this.init();
  },
  methods: {
    async goodsList() {
      this.loading = true;
      const data = await goodsList().finally(() => {
        this.loading = false;
      });
      if (!(data && data.error_code === 0)) {
        return;
      }
      this.goodsListOption.push({
        value: 0,
        label: '请选择'
      });
      data.data.forEach((s) => {
        this.goodsListOption.push({
          value: s.id,
          label: s.goods_name
        });
      });
    },
    async integralConsumptionIndex(page, pageSize) {
      const params = this.getParmas(page, pageSize);
      this.loading = true;
      const data = await integralConsumptionIndex(params).finally(() => {
        this.loading = false;
      });
      if (!(data && data.error_code === 0)) {
        return;
      }
      this.data = data.data;
      this.pagination.current = data.meta?.pagination?.current_page || 0;
      this.pagination.pageSize = data.meta?.pagination?.per_page || 0;
      this.pagination.total = data?.meta?.pagination.total || 0;
      this.count = this.pagination.total;
    },
    onReset() {
      this.params = {
        username_mobile_idcard: '',
        goods_id: 0,
        start_time: '',
        end_time: '',
        page: 1,
        per_page: 10
      };
      this.defaultValue = ['', ''];
      this.pagination.current = 1;
      this.pagination.pageSize = 10;
      this.integralConsumptionIndex();
    },
    handleChange(value) {
      this.params.goods_id = value;
    },
    onChange(datetime, datetimeStr) {
      debugger;
      if (datetime[0] >= datetime[1]) {
        this.params.end_time = moment(datetime[1])
          .subtract(-1, 'days')
          .format('YYYY-MM-DD');
      } else {
        this.params.end_time = datetimeStr[1];
      }

      this.params.start_time = datetimeStr[0];
      this.defaultValue = [this.params.start_time, this.params.end_time];
    },
    onPageChange(page, pageSize) {
      this.pagination.current = page;
      this.pagination.pageSize = pageSize;
      this.integralConsumptionIndex(page, pageSize);
    },
    onSizeChange(current, size) {
      this.pagination.current = 1;
      this.pagination.pageSize = size;
      this.integralConsumptionIndex(1, size);
    },
    getParmas(page, pageSize) {
      this.params.page = page || this.pagination.current;
      this.params.size = pageSize || this.pagination.pageSize;
      this.params.per_page = pageSize || this.pagination.pageSize;
      return this.params;
    },
    onSearch() {
      this.integralConsumptionIndex(1, this.params.size);
    },
    init() {
      this.goodsList();
      this.onReset();
    }
  }
};
</script>

<style  lang="scss" scoped>
.deplete-recording {
  padding: 20px;
  .deplete-recording-query {
    display: flex;
    align-items: center;
    justify-items: center;
    span {
      margin-left: 20px;
    }
  }
  .deplete-table {
    padding: 20px 10px;
  }
  .post-name {
    white-space: normal;
  }
}
</style>
