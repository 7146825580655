<template>
  <div class="deplete-title">
    <span class="deplete-content">
      <span class="deplete-content-span"> {{ title }}</span>
      <span class="deplete-content-span" v-if="count >= 0">({{ count }})</span>
    </span>
  </div>
</template>

<script>
export default {
  name: 'deplete-title',
  props: {
    title: {
      type: String,
      default: () => ''
    },
    count: {
      type: Number,
      default: () => -1
    }
  }
};
</script>
<style lang="scss" scoped>
.deplete-title {
  width: 100%;
  height: 40px;
  border-color: #ddd;
  margin-bottom: 20px;
  background-color: #fff;
  border: 1px solid transparent;
  border-radius: 4px;
  line-height: 40px;
  box-shadow: 0 1px 1px rgb(0 0 0 / 5%);
  background: #f5f5f5;
  padding: 0 15px;
  .deplete-content {
    padding: 0 15px;
    height: 20px;
    border-left: 3px solid #333333;
    .deplete-content-span {
      padding: 0 10px 0 0;
      color: #333;
    }
  }
}
</style>