<script>
import { getCertificateTemplateList, deleteCertificateTemplate } from './api'

import Pagination, {
  getPagination,
  updatePagination
} from '../../../components/table/Pagination.vue'

const columns = [
  {
    width: '140px',
    align: 'center',
    dataIndex: 'id',
    key: 'id',
    title: '模板ID'
  },
  {
    width: '280px',
    align: 'center',
    dataIndex: 'title',
    key: 'title',
    title: '模板名称'
  },
  {
    width: '160px',
    align: 'center',
    dataIndex: 'certificate_template_id_str',
    key: 'certificate_template_id_str',
    title: '模板类型'
  },
  {
    width: '140px',
    title: '操作',
    align: 'center',
    key: 'action',
    scopedSlots: { customRender: 'action' }
  }
]

export default {
  name: 'List',
  components: { Pagination },
  data() {
    return {
      loading: false,
      columns,
      data: [],
      pagination: getPagination()
    }
  },
  created() {
    this.onSearch()
  },
  methods: {
    onSearch() {
      this.getData(1, this.pagination.pageSize)
    },
    onReset() {
      this.onSearch()
    },
    onPageChange(page, pageSize) {
      this.getData(page, pageSize)
    },
    onSizeChange(current, size) {
      this.getData(1, size)
    },
    async getData(page, pageSize) {
      page = page || 1
      pageSize = pageSize || this.pagination.defaultPageSize
      this.loading = true

      const params = {
        page,
        per_page: pageSize
      }
      const data = await getCertificateTemplateList(params).finally(() => {
        this.loading = false
      })
      if(!data || data.error_code) {
        return
      }
      this.data = data?.data || data?.info || []
      this.pagination = updatePagination(this.pagination, data?.meta?.pagination || {})
    },

    onEdit(record) {
      this.$router.push({ path: '/index/certificate/template/list/edit', query: { id: record.id } })
    },
    onCreate() {
      this.$router.push({ path: '/index/certificate/template/list/create' })
    },

    onDelete(record) {
      this.$confirm({
        content: `确认删除模板 ${record.title}？`,
        onOk: () => this.confirmDelete(record)
      })
    },
    async confirmDelete(record) {
      if (this.loading) {
        return
      }
      this.loading = true

      const data = await deleteCertificateTemplate(record.id).finally(() => {
        this.loading = false
      })
      if(!data || data.error_code) {
        this.$message.error(data?.message || '删除失败')
        return
      }
      this.$message.success(data?.message || '删除成功')
      this.getData(this.pagination.current, this.pagination.pageSize)
    }
  }
}
</script>

<template>
  <div>
    <div class="right-top-btn-group">
      <a-button class="right-top-btn" @click="onCreate">新建模板</a-button>
    </div>

    <div class="ph-20">
      <a-table :columns="columns"
               :data-source="data"
               :pagination="false"
               :loading="loading"
               bordered
               row-key="id">
        <span slot="action" slot-scope="text, record">
          <a-button type="link"
                    @click="onEdit(record)">
            编辑
          </a-button>
          <a-button type="link"
                    @click="onDelete(record)">
            删除
          </a-button>
        </span>

        <template slot="footer">
          <Pagination v-if="pagination && pagination.total"
                      :pagination="pagination"
                      class="pagination"
                      @change="onPageChange"
                      @showSizeChange="onSizeChange"></Pagination>
        </template>
      </a-table>
    </div>
  </div>
</template>


<style scoped lang="scss">

</style>
